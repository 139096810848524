import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import React from 'react';
import configData from "../../config.json";
import Loader from "react-js-loader";
import swal from 'sweetalert';
import { Card } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  DayView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  AppointmentTooltip
} from '@devexpress/dx-react-scheduler-material-ui';


const AppointmentTooltipHeader = (({ children, appointmentData, ...restProps }) => {
  localStorage.setItem("classid", appointmentData.id)
  return (
    <AppointmentTooltip.Header
      {...restProps}
      appointmentData={appointmentData}
      onOpenButtonClick={{}}
    >
      <button className='mt-2 p-2 btn btn-warning' onClick={(appointmentData) => {
        console.log(appointmentData)
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('classid', localStorage.getItem("classid"));
        bodyFormData.append('studentid', localStorage.getItem("studentid"));
        axios({
          method: "post",
          url: configData.SERVER_URL + 'classes/joinliveclass',
          data: bodyFormData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }).then(resp => {
          console.log(resp.data, "Room API=-=-=-=")
          if (resp.data.code == 200) {
            // window.location.href = `/studentroom/${resp.data.uuid}/${localStorage.getItem("classid")}`;
            window.location.href = `${configData.SERVER_URL}call?token=${resp.data.uuid}&classid=${localStorage.getItem("classid")}&userid=${localStorage.getItem("studentid")}&username=${localStorage.getItem("student_name")}&userType=student`;
          } else {
            swal({
              title: "Please Wait",
              text: "You will be nottified once the class is started",
              icon: "warning",
              button: "ok",
            });
          }
        })
          .catch(() => {
            swal({
              title: "Server Not Responding",
              text: "Please reload the page",
              icon: "warning",
              button: "ok",
            });
          })
      }}>Start Class</button>
    </AppointmentTooltip.Header>
  )
});

export default class StudentClasses extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      classes: [],
      student: "",
      currentDate: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
      startingHour: 0,
      endingHour: 24,
      mainResourceName: 'colorId',
      resources: []
    };
    localStorage.setItem("studentid", this.props.user[0].studentid)
    localStorage.setItem("student_name", this.props.user[0].firstname)

    this.currentDateChange = (currentDate) => {
      this.setState({ currentDate });
      console.log(currentDate)
      this.getClasses(this.props.user[0].studentid, currentDate)
    };
  }
  componentDidMount() {
    if (this.props.token) {
      window.location = configData.SERVER_URL
    }
    console.log(this.props.user[0].studentid);
    this.setState({
      student: this.props.user[0]
    })
    const today = new Date();
    this.getClasses(this.props.user[0].studentid, today)
    document.getElementById("loader").style.display = "none";
  }

  getClasses(studentid, date) {
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('studentid', studentid);
    bodyFormData.append('date', date.getDay());
    console.log(date.getDay())
    axios({
      method: "post",
      url: configData.SERVER_URL + 'classes/getStudentClasses',
      data: bodyFormData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(resp => {
      console.log(resp)
      if (resp.data.code === 200) {
        let startingHour = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 23:59`).getTime()
        let endingHour = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00`).getTime()
        let classes = []
        let colors = []
        resp.data.classes.forEach(myclass => {
          let starttime = new Date(myclass.starttime)
          let endtime = new Date(myclass.endtime)
          if (new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${starttime.getHours()}:${starttime.getMinutes()}:00`).getTime() < startingHour) {
            startingHour = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${starttime.getHours()}:${starttime.getMinutes()}:00`).getTime()
          }
          if (new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${endtime.getHours()}:${endtime.getMinutes()}:00`).getTime() > endingHour) {
            endingHour = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${endtime.getHours()}:${endtime.getMinutes()}:00`).getTime()
          }
          classes.push({
            title: `${myclass.coursename} ${myclass.noofstudents} students`,
            startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate(), starttime.getHours(), starttime.getMinutes()),
            endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate(), endtime.getHours(), endtime.getMinutes()),
            id: myclass.classid,
            colorId: myclass.packageid
          })
          colors.push({
            id: myclass.packageid, text: `${myclass.coursename} ${myclass.timing} Months`, color: myclass.packagecolor
          })
        });
        this.setState({
          classes: classes,
          startingHour: new Date(startingHour).getHours(),
          endingHour: new Date(endingHour).getHours() == new Date(startingHour).getHours() ? new Date(endingHour).getHours() + 1 : new Date(endingHour).getHours(),
          resources: [
            {
              fieldName: 'colorId',
              title: 'Colors',
              instances: colors
            }
          ]
        });
      } else {
        if (resp.data.code === 201) {
          swal({
            title: "No Class Today",
            text: "You dont have any class today",
            icon: "success",
            button: "ok",
          });
        }
        else {
          swal({
            title: "Server Not Responding",
            text: "Please reload the page",
            icon: "warning",
            button: "ok",
          });
        }
      }
    })
      .catch(() => {
        swal({
          title: "Server Not Responding",
          text: "Please reload the page",
          icon: "warning",
          button: "ok",
        });
      })
  }

  startClass = (classid) => {
    return
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('classid', classid);
    bodyFormData.append('studentid', this.state.student.studentid);
    axios({
      method: "post",
      url: configData.SERVER_URL + 'classes/joinliveclass',
      data: bodyFormData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(resp => {
      console.log(resp.data)
      if (resp.data.code == 200) {
        window.location.href = `/studentroom/${resp.data.uuid}/${classid}`;
      } else {
        swal({
          title: "Please Wait",
          text: "You will be nottified once the class is started",
          icon: "warning",
          button: "ok",
        });
      }
    })
      .catch(() => {
        swal({
          title: "Server Not Responding",
          text: "Please reload the page",
          icon: "warning",
          button: "ok",
        });
      })
  }

  render() {
    return (

      <div className="App">
        <header className="App-header">
          <div className="loader" id="loader">
            <Loader type="spinner-circle" bgColor={"#ffffff"} title={"LOADING..."} color={'#ffffff'} size={100} />
          </div>
          <section className="pricing" id="slotViewSection">
            <div className="container">
              <div className="col-lg-12 col-md-12 mt-6 mt-lg-0">
                <div className="box price featured no-padding">
                  <Card className="p-3">
                    <h3>Live Classes Today (click on the banner of the class to start)</h3>
                    <div className="row d-flex align-items-center justify-content-center">
                      <Paper>
                        <Scheduler data={this.state.classes} height={660}>
                          <ViewState currentDate={this.state.currentDate} onCurrentDateChange={this.currentDateChange} />
                          <DayView startDayHour={this.state.startingHour} endDayHour={this.state.endingHour} />
                          <Toolbar />
                          <DateNavigator />
                          <TodayButton />
                          <Appointments />
                          <AppointmentTooltip
                            showCloseButton={true}
                            headerComponent={AppointmentTooltipHeader}
                          />
                          <Resources
                            data={this.state.resources}
                            mainResourceName={this.state.mainResourceName}
                          />
                        </Scheduler>
                      </Paper>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </section>
        </header>
      </div>
    );
  }

}
