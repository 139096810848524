import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Card} from "react-bootstrap";


function Software() {
  return (
    <div className="App">
      <header className="App-header">
          <Card className="p-3">
            <h1></h1>
          </Card>
      </header>
    </div>
  );
}
export default Software;